<template>
  <div class="p-3 text-end">
    <a
      href="javascript:void(0);"
      class="btn small-cta"
      data-bs-target="#feedbackModal"
      aria-haspopup="true"
      data-bs-toggle="modal"
      @click="clearData"
      ref="openModal"
      v-if="toggleAddButton"
      >New Feedback</a
    >
  </div>
  <FeedbackSlot
    @editFeedback="getFeedback"
    ref="feedbackSlot"
    @noSubscription="toggleFeedbackAddButton"
  />
  <!-- <FeedbackSlot @statusUpdateFeedback="updateFeedbackStatus" ref="feedbackSlot" /> -->
  <div
    class="modal fade"
    id="feedbackModal"
    tabindex="-1"
    aria-labelledby="feedbackModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="feedbackModalLabel">
            {{ actionTitle }} Feedback
          </h5>
          <button
            type="button"
            class="btn-close"
            ref="closeModal"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <form @submit.prevent="saveFeedback" ref="formContainer">
          <div class="modal-body">
            <div class="col-md-12 mb-3">
              <label class="form-label">Category <span>*</span></label>
              <Multiselect
                v-model="feedbackCategoryOptions.value"
                v-bind="feedbackCategoryOptions"
              />
              <div
                class="input-errors"
                v-for="error of v$.feedbackCategoryOptions.value.$errors"
                :key="error.$uid"
              >
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
            <div class="form-group col-md-12">
              <div class="mb-3">
                <label class="form-label">Rating <span>*</span></label>
                <div class="d-flex justify-content-around">
                  <i
                    class="far fa-angry fa-2x"
                    @click="rating = 1"
                    :style="{ color: activeRatingColour.angry }"
                  ></i>
                  <i
                    class="far fa-frown fa-2x"
                    @click="rating = 2"
                    :style="{ color: activeRatingColour.frown }"
                  ></i>
                  <i
                    class="far fa-meh fa-2x"
                    @click="rating = 3"
                    :style="{ color: activeRatingColour.meh }"
                  ></i>
                  <i
                    class="far fa-smile fa-2x"
                    @click="rating = 4"
                    :style="{ color: activeRatingColour.smile }"
                  ></i>
                  <i
                    class="far fa-grin-beam fa-2x"
                    @click="rating = 5"
                    :style="{ color: activeRatingColour.grinBeam }"
                  ></i>
                </div>
                <div
                  class="input-errors"
                  v-for="error of v$.rating.$errors"
                  :key="error.$uid"
                >
                  <div class="error-msg">{{ error.$message }}</div>
                </div>
              </div>
            </div>
            <div class="form-group col-md-12">
              <div class="mb-3">
                <label class="form-label">
                  Feedback
                  <span>*</span>
                </label>
                <textarea class="form-control" v-model="feedback"></textarea>
                <div
                  class="input-errors"
                  v-for="error of v$.feedback.$errors"
                  :key="error.$uid"
                >
                  <div class="error-msg">{{ error.$message }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <!-- <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button> -->

            <button type="submit" class="btn cta-primary">Save</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import FeedbackSlot from "@/components/FeedbackSlot.vue";
import CommonService from "@/services/CommonService";
import FeedbackService from "@/services/FeedbackService";
import Multiselect from "@vueform/multiselect";
import useVuelidate from "@vuelidate/core";
import { required, maxLength, helpers } from "@vuelidate/validators";

export default {
  name: "FeedbackTab",
  components: { Multiselect, FeedbackSlot },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  data() {
    return {
      actionTitle: "New",
      feedback: "",
      rating: "",
      fullPage: true,
      // Follows the hover colour for each icons
      activeRatingColour: {
        angry: "",
        frown: "",
        meh: "",
        smile: "",
        grinBeam: "",
      },
      // Select options
      feedbackCategoryOptions: {
        value: null,
        options: [],
        placeholder: "Select Category",
        noOptionsText: "No categories found",
        canDeselect: false,
        loading: false,
        // required: true,
        searchable: true,
      },
      feedbackId: "",
      isEditing: false,
      toggleAddButton: false,
    };
  },
  validations() {
    return {
      feedback: {
        required: helpers.withMessage("Please enter feedback", required),
        // Max limit is as per the client's requirement
        maxLength: maxLength(500),
      },
      rating: {
        required: helpers.withMessage("Please select rating", required),
      },
      feedbackCategoryOptions: {
        value: {
          required: helpers.withMessage("Please select category", required),
        },
      },
    };
  },
  mounted() {
    this.fetchActiveFeedbackCategories();
  },
  watch: {
    // Note:- rating is a prop (variable) & newVal is its current value
    rating(newVal) {
      // console.log(newVal);
      this.activeRatingColour = {
        angry: "",
        frown: "",
        meh: "",
        smile: "",
        grinBeam: "",
      };
      if (newVal === 1) {
        this.activeRatingColour.angry = "#ff0000";
      } else if (newVal === 2) {
        this.activeRatingColour.frown = "#ff6347";
      } else if (newVal === 3) {
        this.activeRatingColour.meh = "#ffdd47";
      } else if (newVal === 4) {
        this.activeRatingColour.smile = "#96e457";
      } else if (newVal === 5) {
        this.activeRatingColour.grinBeam = "#66ff00";
      }
    },
  },
  methods: {
    async fetchActiveFeedbackCategories() {
      this.feedbackCategoryOptions.loading = true;
      await CommonService.getActiveFeedbackCategories()
        .then((response) => {
          if (response.data.status === "SUCCESS") {
            this.feedbackCategoryOptions.value = null; // to clear previously selected value
            this.feedbackCategoryOptions.options = []; // to clear previously loaded options
            let categories = response.data.feedback_categories;
            if (categories.length > 0) {
              categories.map((category) => {
                let options = {
                  value: category.id,
                  label: category.category_title,
                };
                this.feedbackCategoryOptions.options.push(options);
              });
            } else {
              this.feedbackCategoryOptions.noOptionsText =
                "No categories found";
            }
            this.feedbackCategoryOptions.loading = false;
          }
          if (response.data.status === "ERROR") {
            this.$toast.error(response.data.message);
          }
          if (response.data.status === "VALIDATION_FAILED") {
            let errorsObject = response.data.errors;
            let errorValuesArray = Object.values(errorsObject);

            if (errorValuesArray.length > 0) {
              for (let index = 0; index < errorValuesArray.length; index++) {
                this.$toast.error(errorValuesArray[index]);
              }
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    saveFeedback() {
      this.v$.$touch();
      if (this.v$.$invalid) {
        // console.log(this.v$.$errors);
        return;
      }
      let loader = this.$loading.show({
        // Optional parameters
        container: this.fullPage ? null : this.$refs.formContainer,
      });
      let params = {
        feedback_category_id: this.feedbackCategoryOptions.value,
        rating: this.rating,
        feedback: this.feedback,
        id: this.feedbackId,
      };
      FeedbackService.saveFeedback(params)
        .then((response) => {
          loader.hide();
          if (response.data.status === "SUCCESS") {
            this.$toast.success(response.data.message);
            // trigger the function declared inside the FeedbackSlot component
            this.$refs.feedbackSlot.switchRoute();
            this.$refs.closeModal.click();
            this.v$.$reset(); // reset all validations
            this.feedbackCategoryOptions.value = null; // to clear previously selected value
            this.feedback = "";
            this.feedbackId = "";
            this.isEditing = false;
          }
          if (response.data.status === "ERROR") {
            this.$toast.error(response.data.message);
          }
          if (response.data.status === "VALIDATION_FAILED") {
            // console.log(response.data.errors);
            let errorsObject = response.data.errors;
            // Note:- Object.values gives the values as an array
            let errorValuesArray = Object.values(errorsObject);

            if (errorValuesArray.length > 0) {
              for (let index = 0; index < errorValuesArray.length; index++) {
                this.$toast.error(errorValuesArray[index]);
                // console.log(errorValuesArray[index]);
              }
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getFeedback: function (id) {
      const params = {
        feedback_id: id,
      };
      FeedbackService.getFeedback(params)
        .then((result) => {
          if (result.data.status == "SUCCESS") {
            let feedbackData = result.data.feedback;
            this.$refs.openModal.click();
            /* isEditing flag should be assigned as true only after modal click as it clears data when clicked on modal */
            this.isEditing = true;
            this.actionTitle = "Edit";
            this.feedbackCategoryOptions.value =
              feedbackData.feedback_category_id;
            this.feedback = feedbackData.feedback;
            this.rating = feedbackData.rating;
            this.feedbackId = feedbackData.id;
          } else if (result.data.status == "ERROR") {
            this.$toast.error(result.data.message);
            this.$refs.closeModal.click();
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    toggleFeedbackAddButton(status) {
      this.toggleAddButton = status;
    },
    clearData: function () {
      this.actionTitle = "New";
      this.v$.$reset(); // reset all validations
      this.rating = ""; // default rating value
      this.feedbackCategoryOptions.value = null; // to clear previously selected value
      this.feedback = "";
      this.feedbackId = "";
      this.isEditing = false;
    },
  },
};
</script>
<style scoped>
i.fa-angry:hover {
  color: #ff0000;
}
i.fa-frown:hover {
  color: #ff6347;
}
i.fa-meh:hover {
  color: #ffdd47;
}
i.fa-smile:hover {
  color: #96e457;
}
i.fa-grin-beam:hover {
  color: #66ff00;
}
</style>
